export default {
  proxy_path: "https://wuixvuvzdi.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://y7nrvey8r4.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyBthLqIniCp2bAvxhf4mvKwBT0I6ENtM7M",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.ozminerals.forwoodsafety.com",
  forwood_id_url: "https://id.staging.ozminerals.forwoodsafety.com?redirect_uri=https://mapreport.staging.ozminerals.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.ozminerals.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_u6AkKhc2D",
    userPoolWebClientId: "1rnuib2fqokggi231p7hmrocge",
    cookieStorage: {
      domain: ".staging.ozminerals.forwoodsafety.com",
      secure: true
    }
  }
};
